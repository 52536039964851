/**
 * PinImages is meant to offer an accessible way to change the pin images for result pin
 * on the interactive map page. Given some config, an SVG should be customizable to
 * have branding consistent styling in this file.
 */
class PinImages {
  /**
   * @param {Object} defaultPinConfig The configuration for the default pin
   * @param {Object} hoveredPinConfig The configuration for the hovered pin
   * @param {Object} selectedPinConfig The configuration for the selected pin
   */
  constructor(defaultPinConfig = {}, hoveredPinConfig = {}, selectedPinConfig = {}) {
    this.defaultPinConfig = defaultPinConfig;
    this.hoveredPinConfig = hoveredPinConfig;
    this.selectedPinConfig = selectedPinConfig;
  }

  /**
   * Generate standard theme pin given some parameters
   * @param {string} pin.backgroundColor Background color for the pin
   * @param {string} pin.strokeColor Stroke (border) color for the pin
   * @param {string} pin.labelColor Label (text) color for the pin
   * @param {number} pin.width The width of the pin
   * @param {number} pin.height The height of the pin
   * @param {string} pin.pinCount The index of the pin for the pin text
   * @return {Object} The SVG of the pin, and its width and height
   */
  generatePin ({
    backgroundColor = '#00759e',
    strokeColor = 'black',
    labelColor = 'white',
    width = 40,
    height= 47,
    index = '',
    profile = ''
  } = {}) {
    const svg = `
    <svg version="1.1" id="SALON_LOCATOR_PIN" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
    y="0px" viewBox="0 0 1000 1000" style="enable-background:new 0 0 1000 1000;" xml:space="preserve">
        <style type="text/css">
          .st0{fill:#FFFFFF;}
        </style>
        <path id="BLACK_PIN" d="M901.32,406.09c0.1,65.41-15.5,127.51-42.32,186.55c-68.83,151.52-173.27,274.62-304.93,375.04
          c-46.94,35.8-55.88,36.48-99.07,2.44c-143.4-113.01-259.4-248.34-325.96-420.55C37.27,312.15,184.09,74.55,401.69,18.53
          C653.61-46.32,902.22,145.67,901.32,406.09z"/>
        <g id="PR_00000145034954473003643140000014088752744648660900_">
          <path class="st0" d="M474.57,340.93c0,17.02-2.53,32.04-7.55,45.07c-5.04,13.03-11.73,23.89-20.06,32.57
            c-8.34,8.69-17.98,15.29-28.92,19.8c-10.94,4.52-22.33,6.77-34.13,6.77h-54.19V609.8h-35.96v-368.9h87.02
            c10.76,0,21.71,1.56,32.83,4.68c11.11,3.13,21.18,8.6,30.22,16.42c9.03,7.81,16.41,18.15,22.15,31
            C471.7,305.85,474.57,321.83,474.57,340.93z M438.61,341.2c0-12.11-1.56-22.58-4.69-31.41c-3.12-8.82-7.47-16.01-13.02-21.54
            c-5.56-5.53-11.91-9.6-19.02-12.2c-7.12-2.59-14.85-3.9-23.19-3.9h-48.98v141.73h49.5c16.32,0,30.3-5.96,41.94-17.91
            C432.79,384.04,438.61,365.77,438.61,341.2z"/>
          <path class="st0" d="M668.39,609.8l-57.32-157.36h-34.91V609.8h-60.44v-368.9h105.25c15.28,0,29.35,2,42.21,5.99
            c12.84,4,23.88,10.34,33.09,19.02c9.2,8.69,16.41,19.63,21.62,32.83c5.21,13.2,7.82,29,7.82,47.41c0,14.25-1.82,26.83-5.47,37.78
            c-3.65,10.94-8.25,20.23-13.81,27.87c-5.56,7.64-11.73,13.81-18.5,18.5c-6.78,4.68-13.47,7.9-20.06,9.64l66.18,169.86H668.39z
              M664.22,349.27c0-11.11-1.56-20.32-4.69-27.61c-3.13-7.3-7.21-13.03-12.25-17.2c-5.04-4.17-10.68-7.12-16.93-8.86
            c-6.25-1.74-12.5-2.6-18.76-2.6h-35.43v113.59h35.43c15.28,0,27.87-4.77,37.77-14.33C659.27,382.71,664.22,368.38,664.22,349.27z"
            />
        </g>
    </svg>`;
    return { svg, width, height };
  };

  /**
   * Get the default pin image
   * @param {Number} pinCount The pin index number for the pin label
   * @param {Object} profile The profile data for the entity associated with the pin
   */
  getDefaultPin (index, profile) {
    return this.generatePin({
      backgroundColor: this.defaultPinConfig.backgroundColor,
      strokeColor: this.defaultPinConfig.strokeColor,
      labelColor: this.defaultPinConfig.labelColor,
      width: 40,
      height: 47,
      index: '',
      profile: profile
    });
  }

  /**
   * Get the hovered pin image
   * @param {Number} pinCount The pin index number for the pin label
   * @param {Object} profile The profile data for the entity associated with the pin
   */
  getHoveredPin (index, profile) {
    return this.generatePin({
      backgroundColor: this.hoveredPinConfig.backgroundColor,
      strokeColor: this.hoveredPinConfig.strokeColor,
      labelColor: this.hoveredPinConfig.labelColor,
      width: 60,
      height: 67,
      index: '',
      profile: profile
    });
  }

  /**
   * Get the selected pin image
   * @param {Number} pinCount The pin index number for the pin label
   * @param {Object} profile The profile data for the entity associated with the pin
   */
  getSelectedPin (index, profile) {
    return this.generatePin({
      backgroundColor: this.selectedPinConfig.backgroundColor,
      strokeColor: this.selectedPinConfig.strokeColor,
      labelColor: this.selectedPinConfig.labelColor,
      width: 60,
      height: 67,
      index: '',
      profile: profile
    });
  }
}

export { PinImages };
